<template>
  <div class="m-2">
    
    <bread-crumbs :items="items"></bread-crumbs> 
    <v-alert
      dense
      text
      :type="error.type"
      v-if="!showAlert"
      :style="`direction:`+lang.dir+`;`"
    >
      {{ error.message }} 
    </v-alert>
    <v-row class="mainrow m-1 pa-0 d-none d-md-block" style="direction:rtl">
      <div style="margin:5px;" class="rowTitle">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <div style="display:inline-flex;width:11.111%">
              {{lang.cardid}}: {{ cardid }}
            </div>
            
           
            <div style="display:inline-flex;width:22.222%">
              {{lang.added_date}}: &nbsp;&nbsp; <span style="direction:ltr">{{ card.sdate.substr(0, 10) }}</span>
            </div>
            <div style="display:inline-flex;width:22.222%">
              {{lang.added_time}}: &nbsp;&nbsp; <span style="direction:ltr">{{ card.sdate.substr(11, 10) }}</span>
            </div>

            <div v-if="card.inv_iscancel == 1" style="display:inline-flex;">
              تم الغاء فاتورة الباقة بتاريخ : {{card.inv_canclel_date}} - بواسطة : {{card.inv_cancel_by}} - 
              وذلك بسبب: {{card.inv_cancel_resone}}
            </div>
          </b-card-header>

        </b-card>
      </div> 
      <v-row>
        <v-col cols="12" v-if="card.status < 0" style="background:orange;margin-bottom:5px">
          تم الغاء هذه البطاقة بتاريخ : {{card.cancel_date}} وسبب الالغاء : {{card.cancel_reson}}
          <span style="margin-left:10px;float:left">
            تم الالغاء بواسطة: {{card.cancel_by}}
          </span>
        </v-col>
        <!-- <div style="display:inline-flex;width:11.111%;" v-if="card.status > 0 && card.status < 100">
          <b-button class="mybtn4" @click="saveCard()">حفظ </b-button>
        </div> -->
        
        <!-- <div style="display:inline-flex;width:11.111%">
          <b-button class="mybtn2" style="background:#3d297d !important;color:#FFF" v-b-toggle.add_warning>SMS </b-button>
        </div> -->
        <!-- <div style="display:inline-flex;width:11.111%"  v-if="card.status > 0 && card.status < 100">
          <b-button class="mybtn3" @click="closeCard()">اغلاق البطاقة </b-button>
        </div> -->
        <!-- <div style="display:inline-flex;width:11.111%">
          <b-button class="mybtn2" style="background:#7d2929 !important;color:#FFF" v-b-toggle.add_notes>ملاحظات </b-button>
        </div> -->
        
        <!-- <div style="display:inline-flex;width:11.111%">
          <b-button class="mybtn2" style="background:#00c4fb !important;color:#000" >سجل البطاقة </b-button>
        </div> -->
        
        <div style="display:inline-flex;width:11.111%">
          <b-button class="mybtn2" style="background:#7d2929 !important;color:#FFF" v-b-toggle.add_gallery> {{lang.photo_gallary}} </b-button>
        </div>
        
        <div style="display:inline-flex;width:11.111%" v-if="card.status > 0">
          <b-button class="mybtn1" v-b-toggle.invoice_gen v-if="showaddinv">{{lang.invoices}} </b-button>
          <b-button class="mybtn1" v-else @click="printInvo()">{{lang.print_invoice}} </b-button>
        </div>
        <div style="display:inline-flex;width:11.111%">
          <b-button class="mybtn2" style="background:blue !important;color:#FFF" @click="printMe()">{{lang.print_card}} </b-button>
        </div>
        <div style="display:inline-flex;width:11.111%"  v-if="card.status == -500 && card.status > 0 && card.status < 100 && showaddinv">
          <b-button class="mybtn2" style="background:red !important;color:#fff" v-if="showaddinv" v-b-toggle.cancelCard>الغاء البطاقة </b-button>
        </div>
        <div style="display:inline-flex;width:12.111%" v-if="sums.remain > 0">
          <b-button v-b-toggle.add_addpayment class="mybtn" style="background:green"><i class="fas fa-money-bill"></i>&nbsp;&nbsp; {{lang.make_payment}} </b-button>
        </div>
      </v-row> 
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <v-card
              elevation="1"
              outlined
              shaped
              tile
          >
            <v-card-title>
              <b-button-group style="width:100% !important;">
                <b-button variant="light">{{lang.customer_info}} : </b-button>
                <b-button variant="light"></b-button>
                <b-button variant="light"></b-button>
                <b-button variant="light" style="width:25px;display:none;"><i style="color:#111" class="fas fa-pencil-alt"></i></b-button>
              </b-button-group>
            </v-card-title>
            <v-card-text>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <tbody>
                          <tr>
                            <th style="border:1px solid #ccc;">{{lang.customer_name}}</th>
                            <td>{{ Customer.name }}</td>
                            <th style="border:1px solid #ccc;">{{lang.mobile}}</th>
                            <td>{{ Customer.mobile }}</td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                          </tr>
                          <!-- <tr>
                            <th style="border:1px solid #ccc;">البريد الالكتروني</th>
                            <td>{{ Customer.email }}</td>
                            <th style="border:1px solid #ccc;">المدينة</th>
                            <td>{{ Customer.city }}</td>
                          </tr>
                          <tr>
                            <th style="border:1px solid #ccc;">العنوان</th>
                            <td colspan="3">{{ Customer.Address }}</td>
                          </tr> -->
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        

        <v-col cols="12" md="6" sm="12">
          <v-card
              elevation="1"
              outlined
              shaped
              tile
          >
            <v-row>
              <v-col cols="12" style="padding-top:0px;">
                <v-card-title>
                  <b-button-group style="width:100% !important;">
                    <b-button variant="light">{{lang.car_info}} : </b-button>
                    <b-button variant="light"></b-button>
                    <b-button variant="light"></b-button>
                    <b-button variant="light" style="width:25px;display:none;"><i style="color:#111" class="fas fa-pencil-alt"></i></b-button>
                  </b-button-group>
                </v-card-title>
                <v-card-text>
                  <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <tbody>
                          <tr>
                            <th style="border:1px solid #ccc;">{{lang.plate_number}}</th>
                            <td>{{ car.plate_number }}</td>
                            <th style="border:1px solid #ccc;">{{lang.car_model}}</th>
                            <td>{{ car.car_model }}</td>
                          </tr>
                          <tr>
                            <th style="border:1px solid #ccc;">{{lang.car_year}}</th>
                            <td>{{ car.car_madel_year }}</td>
                            <th style="border:1px solid #ccc;">{{lang.car_color}}</th>
                            <td>{{ car.car_color }}</td>
                          </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                </v-card-text>
              </v-col>
              
            </v-row>
          </v-card>
        </v-col>

      </v-row>

    </v-row>
    
    <v-row class=" m-1 pa-0" :style="`margin-top:-25px;marign-right:5px;direction:`+lang.dir">
      <v-col cols="12" md="5" sm="12">
        <CVLaborTable :canceled="!showaddinv" />
        <CVSubletsTable :canceled="!showaddinv" />
        
        <cardPayments />
      </v-col>
      <v-col cols="12" md="7" sm="12">
        <CVPartsTable :canceled="!showaddinv" />
        <CardExpenses />
        <CVAdditionalRow  :canceled="!showaddinv" :getData="getClicked" :clickedp='card.clicked' />
        <v-row cols="12" md="7" sm="12">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center">{{lang.car_description}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in scrNotes" :key="index">
                <td class="text-center">{{item}}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-row>
      </v-col>
      
    </v-row>
    <v-row class="m-1 pa-0" :style="`margin-top:-25px;marign-right:5px;direction:`+lang.dir">
      <v-col cols="12">
        <div class="accordion" role="invsummary">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
              {{lang.invoice_samary}} </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <v-row>
                    <v-col cols="12" md="3" sm="6">
                        <table class="table">
                          <thead class="thead-dark">
                            <tr>
                              <th colspan="3">{{lang.spare_parts}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{lang.total}}</td>
                              <td></td>
                              <td>{{ sums.parts }}</td>
                            </tr>
                            <tr style="display:none">
                              <td>{{lang.discount}}</td>
                              <td>0</td>
                              <td>0</td>
                            </tr>
                            <tr>
                              <td>{{lang.totals}}</td>
                              <td></td>
                              <td>{{ sums.parts }}</td>
                            </tr>
                          </tbody>
                        </table>
                    </v-col>
                    <v-col cols="12" md="3" offset-md="1" sm="6">
                      <table class="table">
                        <thead class="thead-dark">
                        <tr>
                          <th colspan="3">{{lang.others}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style="display:none">
                          <td>العمالة</td>
                          <td></td>
                          <td>{{ sums.labor }}</td>
                        </tr>
                        <tr>
                          <td>{{lang.work_price}}</td>
                          <td></td>
                          <td>{{ sums.service }}</td>
                        </tr>
                        <tr>
                          <td>{{lang.vat}} 15%</td>
                          <td></td>
                          <td>{{ sums.vats }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </v-col>
                    <v-col cols="12" md="3" sm="6">
                      <table class="table">
                        <thead class="thead-dark">
                        <tr>
                          <th colspan="3">{{lang.total}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{lang.ftotal}}</td>
                          <td></td>
                          <td>{{ sums.total }}</td>
                        </tr>
                        <tr>
                          <td>{{lang.paid}}</td>
                          <td></td>
                          <td>{{ sums.paid }}</td>
                        </tr>
                        <tr>
                          <td>{{lang.remain}}</td>
                          <td></td>
                          <td>{{ sums.remain }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </v-col>
                    <v-col cols="12" md="2" sm="6">
                      <!-- <div style="margin:2px;margin-top:20px" v-if="isCanceled === false"><b-button v-b-toggle.add_addpayment class="mybtn" style="background:green"><i class="fas fa-money-bill"></i>&nbsp;&nbsp; سداد دفعة </b-button></div> -->
                      <!-- <div style="margin:2px;margin-top:20px"><b-button @click="goWhats()" class="mybtn" style="background:green"><i class="fas fa-whatsapp"></i>&nbsp;&nbsp; Whatsapp </b-button></div> -->
                      
                    </v-col>
                  </v-row>
                  
                  <div style="display:inline-flex;width:22.222%;position:fixed;left:45px;bottom:50px;" v-if="card.status > 0 && card.status < 100">
                      <b-button class="mybtn3" style="font-size:1.2em;margin-right:5px;" @click="closeCard()">{{lang.close_card}} </b-button>
                      <b-button style="background:red !important;font-size:1.2em;margin-right:5px;" class="mybtn4" @click="saveCard()">{{lang.save}} </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </v-col>
    </v-row>
    <CreateNewCard />
    <cardViewAction />
    <viewCardWarning />
    <viewCardAddNote />
    <viewCardGallery />
    <printReceipt :cardinfo="card" :customerinfo="Customer" :carinfo="car" :getData="getClicked" :clickedp='card.clicked' />
    <invoice :invinfo="invoicedata" />
    <AddCardPayment :cAmount="sums.remain"  />
    <cancelCard />
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import CVLaborTable from '../components/CVLaborTable.vue'
import CVPartsTable from '../components/CVPartsTable.vue'
import CVSubletsTable from '../components/CVSubletsTable.vue'
import CVAdditionalRow from '../components/CVAdditionalRow.vue'
import CreateNewCard from "../components/CreateNewCard.vue"
import cardViewAction from "../components/cardViewAction.vue"
import viewCardWarning from "../components/viewCardWarning.vue"
import viewCardAddNote from '../components/viewCardAddNote.vue'
import viewCardGallery from "../components/viewCardGallery.vue"
import printReceipt from "../components/printReceipt.vue"
import invoice from "../components/invoice.vue"
import axios from 'axios'
import AddCardPayment from '@/components/addCardPayment.vue'
import cardPayments from '@/components/cardPayments.vue'
import cancelCard from '@/components/cancelCard.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import {SnotifyPosition} from 'vue-snotify';
import CardExpenses from '@/components/card_expenses.vue'
export default{
  components: {CVLaborTable,CVPartsTable,CVSubletsTable,CVAdditionalRow,
                CreateNewCard,cardViewAction,viewCardWarning,viewCardAddNote,
                viewCardGallery,invoice,printReceipt,AddCardPayment,cardPayments
                ,cancelCard,BreadCrumbs,CardExpenses
    },
    name: 'ViewCard',
  data: () => ({
    invsummary: true,
    ishome: false,
    cardid: 0,
    modal: false,
    modal1: false,
    modal2: false,
    invoicedata:[],
    theclicked:[],
    error: {
      message: '',
      type: 'success'
    },
    scrNotes:[],
    showAlert: true,
    card: {
      cardid: '',
      sdate: '',
      delivery: '',
      statusper: '',
      max: 100,
      clicked: [],
      cancel_date: '',
      cancel_by: '',
      cancel_reson: '',
      inv_id:0,
      status: 1
    },
    Customer: {
      name: '',
      mobile: '',
      email: '',
      city: '',
      Address: ' ',
    },
    car:{
      plate_number: '',
      car_madel_year: '',
      car_color: '',
      car_model: '',
    },
    isCanceled:false,
    sums:{
      labor: 0,
      service: 0,
      parts: 0,
      paid: 0,
      vats: 0,
      total: 0,
      remain: 0,
    },
    showaddinv:false,
    deftotpay: 0,
    NewLabors: [],
    NewParts: [],
    NewWork: [],
    NewDone: [],
    NewDel: [],
  }),
  computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function () {
          return {
            text: this.lang.view_card,
            disabled: true,
            to: '/card-view/',
          }
        }
    },
  async created() {
    this.cardid = this.$route.params.cardid;
    this.getIP();
    //this.CheckLogin();
    //console.log(this.$cookies.get(this.COOKIES));
    await this.getCard(); 
    this.calckInv();
    setInterval(() => {
      // console.log(this.card.inv_id);
      this.calckInv();
      this.checkinv();
    }, 1000);
  },
  methods: {
    calcDef(){
      //
    },
    goWhats(){
      window.open('https://wa.me/966565100268',"_blank");
    },
    calckInv(){
      const post = new FormData();
      post.append('type','getCalckInv');
      post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[cardid]',this.cardid);
      axios.post(
        this.$SAMCOTEC.r_path, post 
      ).then((response) => {
        // console.log("vat after calc",response.data);
        const res = response.data;
        this.sums = res.results.data;
      })
    },
    printWindow(){
      window.print();
    },
    closeCard(){
      this.closeMe();
      // let closeit = false;
      // if (this.$snotify.notifications.length > 0) {
      //     this.$snotify.notifications.forEach(notification => {
      //         this.$snotify.remove(notification.id)
      //     });
      // }
      
      // this.$snotify.error('هل أنت متاكد بأنك تريد إغلاق كرت العمل؟',{
      //     timeout: 10000,
      //     showProgressBar: false,
      //     closeOnClick: false,
      //     pauseOnHover: true,
          
      //     position: SnotifyPosition.centerCenter,
      //     buttons: [
      //         {
      //             text: 'أنا متأكد', 
      //             action: (toast) => {
      //                 this.closeMe() 
      //             },
      //             bold: false
      //         },
      //         {
      //             text: 'الغاء الامر', 
      //             action: (toast) => {
      //                 this.$snotify.remove(toast.id); 
      //             } 
      //         },
      //     ]
      // })
    },
    closeMe(){
      const post = new FormData();
      post.append('type','saveCard');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[cardid]',this.cardid);
      post.append('data[close]',1);
      post.append('data[statusper]',this.card.statusper);
      post.append('data[clicked]',this.card.clicked);

      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then((response) => {
        if (this.$snotify.notifications.length > 0) {
            this.$snotify.notifications.forEach(notification => {
                this.$snotify.remove(notification.id)
            });
        }
        const res = response.data;
        //this.showAlert = false;
        console.log(res);
        if(res.error.number == 200){
          this.error.message = this.lang.card_closed;
          this.error.type = 'success';
          
           this.$snotify.success(this.lang.card_closed, '', {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              leftTop:"leftTop"
            });

        }else{
          let messg = this.lang.please_check_data;
          if(res.error.number == 1500){
            messg = this.lang.cant_close_card_when_it_not_fully_paid;
          }
          
          this.$snotify.error(messg, {
              timeout: 10000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              leftTop:"leftTop"
            });
        }
      }).then(() => {
        this.getCard();
      })
    },
    saveCard(){
      // const x = 1;
      // if(x==1){
      //   console.log(this.NewWork);
      //   return false;
      // }
      const post = new FormData();
      post.append('type','saveCard');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[cardid]',this.cardid);
      post.append('data[sdate]',this.card.sdate);
      post.append('data[close]',0);
      post.append('data[delivery]',this.card.delivery);
      post.append('data[statusper]',this.card.statusper);

      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then((response) => {
        const res = response.data;
        if(res.error.number == 200){
          if (this.$snotify.notifications.length > 0) {
              this.$snotify.notifications.forEach(notification => {
                  this.$snotify.remove(notification.id)
              });
          }
          this.$snotify.success(this.lang.card_saved, '', {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              leftTop:"leftTop"
            });
        }else{
          if (this.$snotify.notifications.length > 0) {
              this.$snotify.notifications.forEach(notification => {
                  this.$snotify.remove(notification.id)
              });
          }
          this.$snotify.error(this.lang.please_check_data, '', {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            leftTop:"leftTop"
          });
        }
      }).then(() => {
        this.getCard();
      }).then(() => {
        this.$router.push({name:'Home'})
      })

    },
    getIP()
    {
      const url = this.$SAMCOTEC.ip_url;
      axios.get(url).then((response) => {
        this.$SAMCOTEC.ip = response.data.ip
      })
    },
    getCard(){
      this.cardid = this.$route.params.cardid;
      let post = new FormData();
      post.append('type' , 'getCards');
      const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
      post.append('auth',auth);
      post.append('data[cardid]',this.cardid)
      axios.post(
        this.$store.state.SAMCOTEC.r_path,
        post
      ).then((response) => {
        console.log("res ",response.data);
        const res = response.data;
        this.Customer.name = res.results.data[0].customer;
        this.Customer.mobile = res.results.data[0].mobile;
        this.card.cardid = res.results.data[0].id;
        this.card.sdate = res.results.data[0].date_in;
        this.card.delivery = res.results.data[0].date_out;
        this.card.statusper = res.results.data[0].status;
        this.card.status = res.results.data[0].status;
        this.card.cancel_by = res.results.data[0].cancel_by;
        this.card.cancel_reson = res.results.data[0].cancel_reson;
        this.card.cancel_date = res.results.data[0].cancel_date;
        this.card.inv_id = +parseFloat(res.results.data[0].inv_id);
        this.card.inv_iscancel = res.results.data[0].inv_iscancel;
        this.card.inv_cancel_by = res.results.data[0].inv_cancel_by;
        this.card.inv_cancel_date = res.results.data[0].inv_cancel_date;
        this.card.inv_cancel_resone = res.results.data[0].inv_cancel_resone;
        
        const cl = res.results.data[0].clicked;
        const clt = cl.split(',');
        for(let i = 0;i<clt.length;i++){ 
          this.card.clicked.push(parseInt(clt[i])); 
        }
        this.car.plate_number = res.results.data[0].plate_number;
        this.invoicedata = res.results.data[0];
        this.car.car_model = res.results.data[0].car_model;
        this.car.car_madel_year = res.results.data[0].car_madel_year;
        this.car.car_color = res.results.data[0].car_color;
        this.theclicked = this.card.clicked;
        this.getClicked("testing ",this.card.clicked);

        if(+res.results.data[0].status < 0 || +res.results.data[0].status == 100){
          this.isCanceled = true;
        }
        
        this.scrNotes = res.results.data[0].scrachnotes.split(" -||- ");
      })
    },
    getClicked(clicked){
      this.card.clicked = clicked
    },
    checkinv(){
      // console.log(this.card );
        if(this.card.inv_id == 0 || this.card.inv_id == null || 
            this.card.inv_id.toString() === 'NaN' || this.card.inv_id.toString() == "0" ){
            this.showaddinv = true;
        }
        else{
          this.showaddinv = false;
          this.isCanceled = true;
        }
        if(this.card.status < 0){
          this.isCanceled = true;
        }
        else{
          this.isCanceled = false;
        }
    },
    printInvo(){
      //window.open('../api/print.php?invid='+this.card.inv_id,'_blank');
      this.printPDF(this.card.inv_id);
    },
    printMe(){
      window.open('../api/print.php?cards='+this.$route.params.cardid,'_blank')
    },
    _printPDF ()
    {
        let pdfFrame = document.body.appendChild(document.createElement('iframe'));
        pdfFrame.style.display = 'none';
        pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
        pdfFrame.src = '../api/print.php?cards='+this.$route.params.cardid;
    },
    printPDF (id)
    {
        let pdfFrame = document.body.appendChild(document.createElement('iframe'));
        pdfFrame.style.display = 'none';
        pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
        pdfFrame.src = '../api/print.php?invid='+id;
    },
  }
}
</script>
<style>
.font-size-14{
  font-size:14px;
}
.mybtn{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn2{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mybtn3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mybtn1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.v-application .elevation-1{
 box-shadow: none !important;
}
</style>