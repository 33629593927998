<template>
  <div style="margin:5px;" class="rowTitle">

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
        {{$parent.lang.payments}} </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <v-simple-table striped hover style="width:100%;">
              <template v-slot:default>
                <thead>
                    <tr>
                        <th style="direction:rtl;padding:1px;text-align:center;">{{$parent.lang.payment_amount}}</th>
                        <th style="direction:rtl;padding:1px;text-align:center;width:100px;display:none;">{{$parent.lang.pay_by}}</th>
                        <th style="direction:rtl;padding:1px;text-align:center;width:100px;display:none;">رقم الجوال</th>
                        <th style="direction:rtl;padding:1px;text-align:center;width:100px;">{{$parent.lang.cash}}</th>
                        <th style="direction:rtl;padding:1px;text-align:center;width:100px;">{{$parent.lang.span}}</th>
                        <th style="direction:rtl;padding:1px;text-align:center;width:50px;">{{$parent.lang.date}}</th>
                    </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in items"
                    :key="item.id"
                >
                  <td>{{ item.ftotal }}</td>
                  <td style="display:none;">{{ item.received_from }}</td>
                  <td style="display:none;">{{ item.mobile }}</td>
                  <td>{{ item.paycash }}</td>
                  <td>{{ item.payspan }}</td>
                  <td>{{ item.receipt_date }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'CVLaborTable',
  components: {},
  data: () => ({
    invsummary:true,
    items:[
    ],
    description: '',
    qty: 1,
    price: 0,
    total: 0,
    cardid: 0
  }),
  created(){
    this.cardid = this.$route.params.cardid;
    this.getPayments();
    setInterval(() => {
        this.getPayments();
    }, 5000);
  },
  methods: {
    getPayments(id){
      const formdata = new FormData();
      formdata.append('type','getPayment');
      formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
      formdata.append('data[cardid]',this.cardid);
        axios.post(
          this.$SAMCOTEC.r_path, formdata
        ).then((response) => {
          const res = response.data;
          this.items = res.results.data;
        });
        
      }
      
    },
    
  }
</script>
<style>
.rowTitle{
  background:#FFF;
  color:#000;
  font-size:14px;
  text-align: right;
  padding:3px 3px 5px 3px;
}
</style>