<template>
    <div style="width:80mm; direction:rtl;">
        <b-sidebar aria-labelledby="sidebar-no-header-title" no-header backdrop id="invoice_gen" class="text-center;" width="85rem" style="direction:rtl;" right shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>انشاء فاتورة</span>
        </div>
        <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-row class="p-2 m-2" style="width:21cm;margin:auto !important;">
                <!-- <b-button class="btn-success m-2" style="width:200px;" v-if="invoiced == false" id="printbtns" @click="printPage()">طباعة A4 </b-button> -->
                <!-- <b-button class="btn-success m-2" style="width:200px;" v-if="invoiced == true" id="printbtns" @click="printPage()">طباعة طابعة ايصالات </b-button> -->
                <b-button class="btn-success m-2" style="width:200px;" id="printbtns" @click="createInv()">إنشاء فاتورة </b-button>
            </v-row>
            <div style="width:21cm;margin:auto" id="printmes" v-if="isCanceled == true" >
                نعتذر ولكن لا يمكن انشاء فاتورة لطلب الصيانة وذلك بسبب ان البطاقة ملغاه
            </div>
            <div style="width:21cm;margin:auto" id="printmes3" v-if="isCanceled == false" >
                <v-row class="text-center" style="border: 1px solid #ccc;background:#FFF">
                    <v-col cols="8" style="direction:rtl;padding-top:50px" class="text-start">
                        <div style="font-size:1.2em !important;">{{ settings.company_name }}</div>
                        <div style="font-size:1.2em !important;">جوال: {{settings.mobile}}</div>
                        <div style="font-size:1.2em !important;">{{settings.address}}</div>
                        <div style="font-size:1.2em !important;">الرقم الضريبي: {{settings.vat_number}}</div>
                    </v-col>
                    <v-col cols="4">
                        <img :src="`https://chart.googleapis.com/chart?cht=qr&chs=200x200&ch1=${card.qrdata}`">
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <v-col cols="4" style="font-size:1.5em !important;">
                        رقم الفاتورة: {{ cardid }}
                    </v-col>
                    <v-col cols="4" style="font-size:1.5em !important;">
                        فاتورة ضريبية / VAT Invoice
                    </v-col>
                    <v-col cols="4" style="font-size:1.5em !important;">
                        Date: {{ card.sdate }}
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <th style="width:150px;">اسم العميل<br>Customer Name</th>
                                <td style="background:#fff">{{ Customer.name }}</td>
                                <th style="width:150px;">رقم الجوال<br>Mobile Number</th>
                                <td style="background:#fff">{{ Customer.mobile }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <thead>
                            <tr>
                                <th style="width:25%;">نوع السيارة<br>Car Type</th>
                                <th style="width:25%;">اللون<br>Color</th>
                                <th style="width:25%;">الموديل<br>Model</th>
                                <th style="width:25%;">رقم اللوحة<br>Plate No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="background:#fff">{{ car.car_model }}</td>
                                <td style="background:#fff">{{ car.car_color }}</td>
                                <td style="background:#fff">{{ car.car_madel_year }}</td>
                                <td style="background:#fff">{{ car.plate_number }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                     <b-table-simple style="margin:0">
                        <thead>
                            <tr>
                                <th colspan="8">تفاصيل الفاتورة</th>
                            </tr>
                            <tr>
                                <th>تاريخ الاضافة<br>Added Date</th>
                                <th>رقم القطعة<br>Item Code</th>
                                <th style="width:25%;">اسم القطعة<br>Item Name</th>
                                <th>الكمية<br>Qty</th>
                                <th>السعر<br>Item Price</th>
                                <th>الاجمالي<br>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(serve,index) in serves" :key="index">
                                <td style="background:#fff">{{ serve.created_date }}</td>
                                <td style="background:#fff">-</td>
                                <td style="background:#fff">{{ serve.description }}</td>
                                <td style="background:#fff">-</td>
                                <td style="background:#fff">{{ serve.price }}</td>
                                <td style="background:#fff">{{ serve.total }}</td>
                            </tr>
                            <tr v-for="part in parts" :key="part.id">
                                <td style="background:#fff">{{ part.created_date }}</td>
                                <td style="background:#fff">{{ part.itemid }}</td>
                                <td style="background:#fff">{{ part.description }}</td>
                                <td style="background:#fff">{{ part.qty }}</td>
                                <td style="background:#fff">{{ part.price }}</td>
                                <td style="background:#fff">{{ part.total }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                         
                                <td colspan="4" style="color:black;font-weight:bold;text-align:left;">المجموع بدون ضريبة</td>
                                <td colspan="2" style="color:black;font-weight:bold">{{ card.total }}</td>
                            </tr>
                            <tr>
                                
                                <td colspan="4" style="color:black;font-weight:bold;text-align:left;">الضريبة المضافة 15%</td>
                                <td colspan="2" style="color:black;font-weight:bold">{{ card.vat }}</td>
                            </tr>
                            <tr>
                                
                                <td colspan="4" style="color:black;font-weight:bold;text-align:left;">الاجمالي مع الضريبة</td>
                                <td colspan="2" style="color:black;font-weight:bold">{{ card.ftotal }}</td>
                            </tr>
                        </tfoot>
                    </b-table-simple>
                </v-row>

                <v-row class="text-center" style="margin-top:1cm;border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <th style="width:150px;">اسم العميل</th>
                                <td style="background:#fff;width:30%;">{{ Customer.name }}</td>
                                <th style="width:150px;">رقم الجوال</th>
                                <td style="background:#fff">{{ Customer.mobile }}</td>
                                <th style="width:150px;">التوقيع</th>
                                <td style="background:#fff;width:30%;"></td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
            </div>
            <footer style="width:21cm;margin:1cm auto;">
                <v-row>
                    <span style="float:right;margin-right:5px;">{{ settings.terms }}</span>
                </v-row>
            </footer>
    </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'

import {SnotifyPosition} from 'vue-snotify';
export default {
    
    data() {
        return {
            isCanceled: false,
            cardid: 0,
            card: {
                cardid: '',
                sdate: '',
                delivery: '',
                statusper: '',
                max: 100,
                clicked: [],
                total: 0,
                vat: 0,
                ftotal: 0,
                qrdata: 'asdfaslfk;asdlkf kjl; kajsd;f ;lkj k'
            },
            Customer: {
                name: '',
                mobile: '',
                email: '',
                city: '',
                Address: ' ',
            },
            car:{
                plate_number: '',
                car_madel_year: '',
                car_color: '',
                car_model: '',
            },
            settings:[

            ],
            parts:[],
            serves:[],
            totals:{
                pprice:0,
                ptotal:0,
                sprice:0,
                stotal:0,
                allprice:0,
                alltotal:0,
            },
            invoiceinfo: [],
            invoiced: false,
            
        }
        
    },
    created() {

        this.cardid = this.$route.params.cardid;
        if(+this.$parent.card.statusper < 0){
            this.isCanceled = true;
        }else{
            this.isCanceled = false;
        }
        if(this.invoiceinfo.length != 0){
            this.invoiced = true;
        }else{
            this.invoiced = false;
        }
        this.getCard();
        this.getInvoice();
        this.getSettings(); 
        
        // setInterval(()=>{
        //     this.getparts();
        //     this.getServices();
        //     this.calcTotal();
        // },1500);
    },
    methods: {
        createInv(){
            document.getElementById('printbtns').disabled = true;
            setTimeout(() => {
                document.getElementById('printbtns').disabled = false;
            },5000)
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            this.$snotify.error('في حالة انشاء فاتورة فانك لن تستطيع أن تقوم بأي تعديل على الكرت', 'تنبيه', {
                timeout: 1000000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                position: SnotifyPosition.centerCenter,
                buttons: [
                    {
                        text: 'متأكد', 
                        action: (toast) => {
                            this.doCreate();
                            this.$snotify.remove(toast.id); 
                        },
                        bold: false
                    },
                    {
                        text: 'اغلاق', 
                        action: (toast) => {
                            document.getElementById('printbtns').disabled = false;
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            });
        },
        printInvo(id){
            this.$router.push({name: 'Home'})
            window.open('../api/print.php?invid='+id,'_blank');
        },
        doCreate(){
            document.getElementById('printbtns').disabled = true;
            const formdata = new FormData();
            formdata.append('type','createInvoice');
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.printInvo(res.results.data);
                this.$partent.getCard();
                this.$router.push({name: 'Home'})
            }).then(() => {
                this.$router.push({name: 'Home'})
            }); 
        },
        getInvoice(){
            const formdata = new FormData();
            formdata.append('type','getInvoice');
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);

            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.invoiceinfo = res.results.data.results;
            });
        },
        calcTotal(){
            const parts = this.parts;
            const serves = this.serves;
            
            for(let i=0;i<parts.length;i++){
                this.totals.ptotal = +this.totals.ptotal + +parts[i].total;
            }
            
            for(let k=0;k<serves.length;k++){
                this.totals.stotal = +this.totals.stotal + +serves[k].total;
            }

            this.totals.alltotal = +this.totals.ptotal + this.totals.stotal
            const t = this.$calcVat(this.totals.alltotal);
        },
        getparts(){
            const formdata = new FormData();
            formdata.append('type','getCItem');
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            formdata.append('data[type]','parts');
            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.parts = res.results.data.results;
            });
            
        },
        getServices(){
            const post = new FormData();
            post.append('type','getCItem');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[cardid]',this.cardid);
            post.append('data[type]','service');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((responses) => {
                const ress = responses.data;
                this.serves = ress.results.data.results;
            });
        },
        getCard(){
            this.cardid = this.$route.params.cardid;
            let post = new FormData();
            post.append('type' , 'getCards');
            const auth = this.$cookies.get(this.$COOKIEPhase);
            post.append('auth',auth);
            post.append('data[cardid]',this.cardid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,
                post
            ).then((response) => {
                const res = response.data;
                this.Customer.name = res.results.data[0].customer;
                this.Customer.mobile = res.results.data[0].mobile;
                this.card.cardid = res.results.data[0].id;
                this.card.sdate = res.results.data[0].date_in;
                this.card.delivery = res.results.data[0].date_out;
                this.card.statusper = res.results.data[0].status;
                this.card.total = res.results.data[0].total;
                this.card.ftotal = res.results.data[0].ftotal;
                this.card.vat = res.results.data[0].vat;
                const cl = res.results.data[0].clicked;
                const clt = cl.split(',');
                for(let i = 0;i<clt.length;i++){ 
                this.card.clicked.push(parseInt(clt[i])); 
                }
                this.car.plate_number = res.results.data[0].plate_number;
                this.invoicedata = res.results.data[0];
                this.car.car_model = res.results.data[0].car_model;
                this.car.car_madel_year = res.results.data[0].car_madel_year;
                this.car.car_color = res.results.data[0].car_color;
                this.theclicked = this.card.clicked;
                this.getClicked("testing ",this.card.clicked);
            })
        },
        getClicked(clicked){
            this.card.clicked = clicked
        },
        printPage(){
            let routeData = this.$router.resolve({path: '/print-invoice/'+this.cardid, query: {data: "someData"}});
            window.open(routeData.href, '_blank');
        },
        getSettings(){
            const post = new FormData();
            post.append("type",'getSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        }
    }
}
</script>
<style>
#printme *{
    direction:rtl;
    font-size:12px !important;
}
#printme{
    font-size:12px !important;
}

</style>