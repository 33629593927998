<template>
    <div class="px-3 py-2">
        <b-sidebar id="cancelCard" backdrop width="20rem" no-header style="direction:ltr" right title="الغاء بطاقة" shadow >
<template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>الغاء بطاقة</span>
        </div>
        <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-form ref="cform" v-model="cval">
            <v-row class="pa-2 m-2">
                <v-col cols="12">
                    <v-textarea label="سبب الالغاء" counter="250" v-model="cancelres" :rules="cRules">{{ cancelres }}</v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="المبلغ المرتجع" type="number" v-model="returnMoney" :rules="rRules">{{ returnMoney }}</v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="رقم الجوال" readonly type="number" v-model="mobile">{{ mobile }}</v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="اسم العميل" readonly v-model="full_name">{{ full_name }}</v-text-field>
                </v-col>
            </v-row>
            </v-form>
</template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='cancelCard()' class="ma-2">الغاء البطاقة</b-button>
                </div>
            </template>
        </b-sidebar>
    </div> 
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            cval: false,
            formtitle: 'الغاء بطاقة عمل',
            cancelres: '',
            cRules:[
                v => !!v || 'لا يمكن الغاء الفاتورة بدون كتابة سبب الالغاء',
                v => v.length > 10 || 'سبب الالغاء يجب ان يكون اكثر من عشر احرف',
                v => v.length <= 250 || 'اكبر عدد للحروف هو 250 حرف'
            ],
            rRules: [
                v => v <= this.pricetotal || 'لا يمكن ان يتجاوز المبلغ ' + this.pricetotal + ' ريال'
            ],
            cardid: 0,
            returnMoney: 0,
            pricetotal: 0,
            mobile: '',
            full_name: '',
        }
    },
    created() {
        this.cardid = this.$route.params.cardid;
        this.pricetotal = this.$parent.sums.total;
        this.mobile = this.$parent.Customer.mobile;
        this.full_name = this.$parent.Customer.name;
    },
    methods: {
        cancelCard(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.cform.validate()){
                return false;
            }
            this.$snotify.error('هل أنت متأكد بانك تريد الغاء البطاقة', 'تحذير', {
                timeout: 6000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                buttons: [
                    {
                        text: 'نعم متاكد', 
                        action: (toast) => {
                            this.cancelConfirmed();
                            this.$snotify.remove(toast.id); 
                        },
                        bold: false
                    },
                    {
                        text: 'اغلاق', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            })
        },
        cancelConfirmed(){
            const post = new FormData();
            post.append("type","cancelCard");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[cardid]",this.cardid);
            post.append("data[cancelres]",this.cancelres);
            post.append("data[returnMoney]",this.returnMoney);
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                if(res.error.number == 200){
                    this.$snotify.success(res.error.message,"الغاء بطاقة",
                    {
                        timeout: 3000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.$parent.getCard();
                    document.getElementById("cancelCard").style.display = "none"
                }else{
                    this.$snotify.error(res.error.message,"الغاء بطاقة",
                    {
                        timeout: 3000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    })
                }
            })
        }
    },
}
</script>