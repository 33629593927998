<template>
    <div style="width:80mm; direction:rtl;">
        <b-sidebar backdrop id="invoice_print" aria-labelledby="sidebar-no-header-title" no-header class="text-center" title="طباعة كرت" width="100rem" style="direction:ltr" right shadow >
<template #default="{ hide }">
      <div class="m-1 text-start" id="printbtnI" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>طباعة الكرت</span>
        </div>
        <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>
            <v-row class="p-2 m-2" style="width:21cm;margin:auto !important;text-align:right">
                    <b-button class="btn-success" style="width:100px;" id="printbtn" @click="print()">طباعة </b-button>
            </v-row>

            <div style="width:21cm;margin:auto" id="printMe" >
                <v-row class="text-center" style="border: 1px solid #ccc;background:#FFF">
                    <v-col cols="4" style="direction:rtl">
                        <div style="font-size:1.5em !important;">{{ settings.company_name }}</div>
                        <div style="font-size:1.5em !important;">جوال: {{settings.mobile}}</div>
                        <div style="font-size:1.5em !important;">{{settings.address}}</div>
                        <div style="font-size:1.2em !important;">الرقم الضريبي: {{settings.vat_number}}</div>
                    </v-col>
                    <v-col cols="4"></v-col>
                    <v-col cols="4" style="direction:ltr">
                        <div style="font-size:1.2em !important;">{{settings.company_name_en}}</div>
                        <div style="font-size:1.5em !important;">جوال: {{settings.mobile}}</div>
                        <div style="font-size:1.5em !important;">{{settings.address_en}}</div>
                        <div style="font-size:1.2em !important;">VAT ID: {{settings.vat_number}}</div>
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <v-col cols="4" style="font-size:1.5em !important;">
                        رقم الكرت: {{ cardid }}
                    </v-col>
                    <v-col cols="4" style="font-size:1.5em !important;">
                        كرت اصلاح
                         / Job Card
                    </v-col>
                    <v-col cols="4" style="font-size:1.5em !important;">
                        Date: {{ card.sdate }}
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <th style="width:150px;">اسم العميل<br>Customer Name</th>
                                <td style="background:#fff">{{ Customer.name }}</td>
                                <th style="width:150px;">رقم الجوال<br>Mobile Number</th>
                                <td style="background:#fff">{{ Customer.mobile }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <thead>
                            <tr>
                                <th style="width:25%;">نوع السيارة<br>Car Type</th>
                                <th style="width:25%;">اللون<br>Color</th>
                                <th style="width:25%;">الموديل<br>Model</th>
                                <th style="width:25%;">رقم اللوحة<br>Plate No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="background:#fff">{{ car.car_model }}</td>
                                <td style="background:#fff">{{ car.car_color }}</td>
                                <td style="background:#fff">{{ car.car_madel_year }}</td>
                                <td style="background:#fff">{{ car.plate_number }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                     <b-table-simple style="margin:0">
                        <thead>
                            <tr>
                                <th colspan="3">الخدمات</th>
                            </tr>
                            <tr>
                                <th>تاريخ الاضافة<br>Added Date</th>
                                <th style="width:50%;">الوصف<br>Description</th>
                                <th>السعر<br> Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(serve,index) in serves" :key="index">
                                <td style="background:#fff">{{ serve.created_date }}</td>
                                <td style="background:#fff">{{ serve.description }}</td>
                                <td style="background:#fff">{{ serve.price }}</td>
                            </tr>
                            <tr>
                                <td style="color:green;font-weight:bold"></td>
                                <td style="color:green;font-weight:bold">المجموع</td>
                                <td style="color:green;font-weight:bold">{{ totals.stotal }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                     <b-table-simple style="margin:0">
                        <thead>
                            <tr>
                                <th colspan="6">قطع الغيار</th>
                            </tr>
                            <tr>
                                <th>تاريخ الاضافة<br>Added Date</th>
                                <th>رقم القطعة<br>Item Code</th>
                                <th style="width:25%;">اسم القطعة<br>Item Name</th>
                                <th>الكمية<br>Qty</th>
                                <th>السعر<br>Item Price</th>
                                <th>الاجمالي<br>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(part,index) in parts" :key="index">
                                <td style="background:#fff">{{ part.created_date }}</td>
                                <td style="background:#fff">{{ part.itemid }}</td>
                                <td style="background:#fff">{{ part.description }}</td>
                                <td style="background:#fff">{{ part.qty }}</td>
                                <td style="background:#fff">{{ part.price }}</td>
                                <td style="background:#fff">{{ part.total }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="color:green;font-weight:bold">المجموع</td>
                                <td style="color:green;font-weight:bold">{{ totals.pprice }}</td>
                                <td style="color:green;font-weight:bold">{{ totals.ptotal }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <b-table-simple style="margin:0">
                            <tbody>
                                <tr>
                                    <td style="background:#fff;white-space: pre-wrap;font-size:1.5em !important;">{{ settings.tafweed }}</td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                    </v-col>
                    <v-col cols="6">
                        <PrintAdditional />
                    </v-col>
                </v-row>
                <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <th style="width:150px;">اسم العميل</th>
                                <td style="background:#fff;width:30%;">{{ Customer.name }}</td>
                                <th style="width:150px;">رقم الجوال</th>
                                <td style="background:#fff">{{ Customer.mobile }}</td>
                                <th style="width:150px;">التوقيع</th>
                                <td style="background:#fff;width:30%;"></td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </v-row>
            </div>
            <footer style="width:21cm;margin:20px auto;">
                <v-row>
                    <span style="float:right;margin-right:5px;">{{ settings.terms }}</span>
                </v-row>
            </footer>
        </template>
            

        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import PrintAdditional from './printAdditional.vue'
export default {
  components: { PrintAdditional },
    
    data() {
        return {
            cardid: 0,
            card: {
                cardid: '',
                sdate: '',
                delivery: '',
                statusper: '',
                max: 100,
                clicked: [],
            },
            Customer: {
                name: '',
                mobile: '',
                email: '',
                city: '',
                Address: ' ',
            },
            car:{
                plate_number: '',
                car_madel_year: '',
                car_color: '',
                car_model: '',
            },
            settings:[

            ],
            parts:[],
            serves:[],
            totals:{
                pprice:0,
                ptotal:0,
                sprice:0,
                stotal:0,
            }
        }
    },
    created() {
        this.cardid = this.$route.params.cardid;
        this.getCard();
        this.getSettings(); 
        // setTimeout(() =>{
        //     this.getparts();
        //     this.getService();
        //     this.calcTotal();
        // },3000);
    },
    methods: {
        async print () {
            // Pass the element id here
            // await this.$htmlToPaper('printMe');
            document.getElementById('printbtn').style.display = 'none';
            document.getElementById('printbtnI').style.display = 'none';
            let myhead = document.getElementsByTagName('head')[0].innerHTML;
            let _mhtml = document.getElementById('invoice_print').innerHTML;
            _mhtml += " \n";
           
            const winHtml = `<!DOCTYPE html dir="rtl">
                <html>
                    <head>
                        ` + myhead + `
                    </head>
                    <body style="direction:rtl">
                        ` + _mhtml + `

                        
                    </body>
                </html>`;
            const winUrl = URL.createObjectURL(
                new Blob([winHtml], { type: "text/html" })
            );
            const win = window.open(
                winUrl,
                "win",'_blank'
            );
            document.getElementById('printbtn').style.display = 'block';
            document.getElementById('printbtnI').style.display = 'block';
            // var newstr = document.getElementsByClassName('printMe')[0].innerHTML
            // document.body.innerHTML = newstr
            // var oldstr = document.body.innerHTML
            // window.print()
            // document.body.innerHTML = oldstr
            // return false

        },
        calcTotal(){
            const parts = this.parts;
            const serves = this.serves;
            let pprice = 0;
            let ptotal = 0;
            let stotal = 0;
            for(let i=0;i<parts.length;i++){
                pprice = +pprice + +parts[i].price;
                ptotal = +ptotal + +parts[i].total;
            }
            
            for(let i=0;i<serves.length;i++){
                stotal = +stotal + +serves[i].total;
            }
            this.totals.pprice = pprice;
            this.totals.ptotal = ptotal;
            this.totals.stotal = stotal;
        },
        getparts(){
            const formdata = new FormData();
            formdata.append('type','getCItem');
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            formdata.append('data[type]','parts');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.parts = res.results.data.results;
            });
            
        },
        getService(){
            const formdata = new FormData();
            formdata.append('type','getCItem');
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            formdata.append('data[type]','service');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.serves = res.results.data.results;
            });
        },
        getCard(){
            this.cardid = this.$route.params.cardid;
            let post = new FormData();
            post.append('type' , 'getCards');
            const auth = this.$cookies.get(this.$COOKIEPhase);
            post.append('auth',auth);
            post.append('data[cardid]',this.cardid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,
                post
            ).then((response) => {
                const res = response.data;
                this.Customer.name = res.results.data[0].customer;
                this.Customer.mobile = res.results.data[0].mobile;
                this.card.cardid = res.results.data[0].id;
                this.card.sdate = res.results.data[0].date_in;
                this.card.delivery = res.results.data[0].date_out;
                this.card.statusper = res.results.data[0].status;
                const cl = res.results.data[0].clicked;
                const clt = cl.split(',');
                for(let i = 0;i<clt.length;i++){ 
                this.card.clicked.push(parseInt(clt[i])); 
                }
                this.car.plate_number = res.results.data[0].plate_number;
                this.invoicedata = res.results.data[0];
                this.car.car_model = res.results.data[0].car_model;
                this.car.car_madel_year = res.results.data[0].car_madel_year;
                this.car.car_color = res.results.data[0].car_color;
                this.theclicked = this.card.clicked;
                this.getClicked("testing ",this.card.clicked);
            })
        },
        getClicked(clicked){
            this.card.clicked = clicked
        },
        printPage(){
            let routeData = this.$router.resolve({path: '/print-card/'+this.cardid, query: {data: "someData"}});
            window.open(routeData.href, '_blank');
        },
        getSettings(){
            const post = new FormData();
            post.append("type",'getSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        }
    }
}
</script>
<style>
#printme *{
    direction:rtl;
    font-size:12px !important;
}
#printme{
    font-size:12px !important;
}

</style>