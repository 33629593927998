<template>
  <div :style="`direction: `+this.$parent.lang.dir+`;margin-top:-48px;`">
    <div class="langbtn">
        <v-btn class="btn-sm btn success" style="width:120px;" @click="changeLang('ar')">عربي</v-btn> 
        <v-btn class="btn-sm btn" style="color:#fff;background:#FF3300;margin: 0 5px;width:120px;" @click="changeLang('en')">English</v-btn>
    </div>
    <v-row>
      <v-col id="lgoopart" cols="12" md="6" class="hidden-sm-only hidden-xs-only text-center loginimgside" style="background:#FFF;text-align:center;">
      <img :src="`../img/samcotec-logo.png`" style="margin-top:30%;width:180px">
      <div style="text-align:center;position:fixed;bottom:50px;background:#eee;left:0;right:0;border:1px solid #ccc">
          <div style="font-size:1em;">
            SAMCOTEC علامة تجارية مسجلة في وزارة التجارة والصناعة رقم التسجيل
            1435022395 الفئة 38 - 
            شركة سام المتقدمة لتقنية المعلومات المحدودة 
            <br>
            اعتماد هيئة الزكاه والدخل والجمارك
            
            خدمات ضريبة القيمة المضافة رقم الترخيص: 14793 تاريخ 23-07-1441هـ
          </div>
          <div style="font-size:1.2em;">
            <a :href="`https://`+demowebsite" style="color:#000;text-decoration:none;font-size:1em">{{demowebsite}}</a> | 
            <a href="mailto:sales@samcotec.com" style="color:#000;text-decoration:none;font-size:1em">sales@samcotec.com</a>
            
          </div>
          <div style="font-size:1.2em;">
              للمبيعات والدعم الفني : 0595907777
          </div>
          <div style="font-size:1.2em;">
              الرياض - حي الحمراء طريق الملك عبد الله
          </div>
      </div>
      </v-col>
      <v-col cols="12" md="6" sm="12" class="text-center">
        <v-row :style="`direction: `+this.$parent.lang.dir+`;`">
            <v-col cols="12" md="6" sm="12" offset-md="3" class="order-md-last" style="margin-top:15%">
                <div style="border:1px solid #ccc;">
                    <v-card style="margin:5px;">
                        <!-- <div class="text-center;"><img :src="`${publicPath}/img/e-car-logo.png`" style="height:50px;"></div> -->
                        <v-card-text>
                        <v-text-field id='username' v-model="username" @keyup.enter="checkEntry()" type="text" :label="$parent.lang.login_name"></v-text-field>
                        <v-text-field id="password" v-model="password" @keyup.enter="checkEntry()" type="password" :label="$parent.lang.password"></v-text-field>
                        <div :style="`direction:`+$parent.lang.dir">
                          <v-switch
                              v-model="savelogin"
                              :label="$parent.lang.save_login_info "
                          ></v-switch>
                        </div>
                        <b-button @click="doLogin()" variant="success" type="button" style="font-size: small;width:50%"> {{$parent.lang.login}}&nbsp;	&nbsp;	<i class="fas fa-sign-in-alt"></i></b-button>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>  
        </v-row>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    publicPath: window.location.origin+process.env.BASE_URL,
    username: '',
    password: '',
    errorAlert: false,
    errorMessage: '',
    savelogin: false,
    en: false,
  }),
  created() {
    this.changeLang(this.$store.state.deflang);
  },
  methods:{
    changeLang(id) {
      this.$store.state.deflang = id
      this.$cookies.set(this.$store.state.LangCooki, id)
      if(id == 'en'){
        this.$parent.lang = this.$store.state.lang.en;
        this.$parent.lang.dir = "ltr";
        this.$parent.lang.ldir = "rtl";
        this.$parent.lang.login_name = "Username";
        this.$parent.lang.password = "Password";
        this.$parent.lang.save_login_info = "Save login info";
        this.$parent.lang.login = "Login"
      }else{
        this.$parent.lang = this.$store.state.lang.ar;
        this.$parent.lang.dir = "rtl";
        this.$parent.lang.ldir = "ltr";
        this.$parent.lang.login_name = "اسم الدخول";
        this.$parent.lang.password = "كلمة المرور";
        this.$parent.lang.save_login_info = "حفظ بيانات الدخول";
        this.$parent.lang.login = "تسجيل الدخول"
      }
    },
    checkEntry(){
      if(this.password == '' && this.username == ''){
        return false;
      }else{
        this.doLogin();
      }
    },
    divHieght(){
      //console.log((Math.floor(window.innerHeight) / 2))
      let e = document.getElementById('lgoopart');
      if(typeof e !== 'undefined' && e != null ){
        e.style.height = window.innerHeight+'px';
      }
      let el = document.getElementById('ecarlogo');
      if(typeof el !== 'undefined' && el != null){
        //el.style.marginTop = Math.floor((Math.floor(window.innerHeight) / 2)) - 60 + 'px';
      }
    },
    getIP()
    {
      const url = this.$SAMCOTEC.ip_url;
      axios.get(url).then((response) => {
        this.$SAMCOTEC.ip = response.data.ip
      })
    },
    doLogin(){

      let pp = new FormData();
      let type = 'register';

      pp.append('data[username]',this.username);
      pp.append('data[password]',this.password);
      pp.append('type',type);

      axios.post(
          this.$store.state.SAMCOTEC.r_path
          ,pp
      ).then((response) => {
          
          let res = response.data;
          // console.log(res);
          if(res.error.number == 200){
            
              let code = res.results.data.ID+'||'+res.results.hash+'||'+this.ip+'||'+res.results.time+'||'+this.$cookies.get(this.$store.state.LangCooki);
              this.$cookies.set(this.$store.state.COOKIEPhase,code,86400);
              //document.getElementById('HelloID').innerHTML = this.full_name
              this.$store.state.group_id = res.results.data.group_id;
              this.$router.push({name: 'Home'})
              //window.location.href = '/home'
          }else{
            this.errorAlert = true;
            this.errorMessage = response.data.error.number+': '+response.data.error.message;
          }
      });
    }
  },
  mounted () {
    this.divHieght();
    this.getIP();
    //console.log("hi ",this.$cookies.get('ECAR-SAMCOTEC'))
  },
  computed: {
    demowebsite: function(){
      if(this.$store.state.SystemName == 'Fatoora'){
        return 'www.samcotec.com';
      }else{
        return 'www.e-carstech.com';
      }
    }
  }
}
</script>

<style>
body{
  height: 100%;
}
.loginimgside{
  width:50%;
  background: #111;
  
}
.langbtn{
  margin-inline-start: 10px;
  position:fixed;
  top:10px;
}
</style>