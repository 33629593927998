<template>
    <FormLogin />
</template>

<script>
import FormLogin from "../components/FormLogin.vue";
export default{
    components: {FormLogin},
    data() {
        return {
            
        }
    },
    beforeCreate() {
        this.$store.state.connected = false;

    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    }
}
</script>
